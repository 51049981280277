import React from 'react';
import theme from '../styles/theme.module.scss';
import Project from './home-projects-list-project';
import styles from './home-projects-list.module.scss';

function ProjectsList(props) {
  let counter = 0;

  function renderText(project) {
    const grid1 = project.grid.gridStart;
    const grid2 = project.grid.gridEnd;

    if (grid1 == 0 && grid2 == 13) {
      return null;
    } else {
      if (counter == 5) {
        counter = 0;
      }
      counter++;
      return counter;
    }
  }

  return (
    <ul className={`${styles.root} ${theme.gdcont} ${theme.gmcont}`}>
      {(props.nodes ?? []).map((node, index) => (
        <Project {...node} key={index} counter={renderText(node)} />
      ))}
    </ul>
  );
}

export default ProjectsList;
