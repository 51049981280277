import { Link } from 'gatsby';
import React from 'react';
import Asset from './asset';
import styles from './home-projects-list.module.scss';
import theme from '../styles/theme.module.scss';
import masks from './masks.module.scss';

function Project(props) {
  const asset = props.assets[0];
  const grid1 = props.grid.gridStart;
  const grid2 = props.grid.gridEnd;

  const width = (grid2 - grid1) * 160;
  const isFullRow = grid1 === 0 && grid2 === 13;

  if (isFullRow) {
    const isTitleOnly = props.fullRowLayout === 'titleOnly';
    const fullRowStyles = isTitleOnly ? styles.fullrowTitleOnly : styles.fullrowLegacy;
    const headingStyles = isTitleOnly ? theme.fd5 : theme.h1_founders

    return (
      <li
        key={props.index}
        className={[
          styles.project,
          theme.mB2,
          fullRowStyles,
          theme.gdfull,
          theme.gmfull,
          theme.gdcont,
          theme.gmcont,
        ].join(' ')}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}>
        <Link
          className={[theme.gdfull, theme.gmfull].join(' ')}
          to={`/work/${props.projectLink.slug.current}`}>
          <Asset asset={asset} width={width} />
        </Link>

        <div
          className={[
            styles.project_meta,
            theme.gdmain,
            theme.gmmain,
            theme.fdcont,
            theme.mT11,
          ].join(' ')}>
          <Link className={styles.project_hover} to={`/work/${props.projectLink.slug.current}`}>
            <h2 className={[styles.project_title, headingStyles].join(' ')}>
              {props.projectLink.title}
            </h2>
          </Link>
          <p className={[styles.project_copy, theme.fd5].join(' ')}>{props.projectLink.tagline}</p>
          <ul className={styles.project_cat}>
            {props.projectLink.categories && (
              <>
                {props.projectLink.categories.map(function(cat, index) {
                  return <li key={index}>{cat.title}</li>;
                })}
              </>
            )}
          </ul>
        </div>
      </li>
    );
  }

  const gridstart = `gds${grid1}`;
  const gridend = `gde${grid2}`;
  const countClass = `nth${props.counter}`;
  const mask = props.maskingShape;

  return (
    <li
      key={props.index}
      className={[
        styles.project,
        styles.gridrow,
        theme[gridstart],
        theme[gridend],
        theme.gmmain,
        theme.mB2,
        styles[countClass],
      ].join(' ')}>
      <Link
        className={[styles.project_link].join(' ')}
        to={`/work/${props.projectLink.slug.current}`}>
        <div className={[styles.project_assetwrapper, masks[mask]].join(' ')}>
          <div className={styles.project_asset}>
            <Asset asset={asset} maxWidth={width} className={styles.entryAsset} />
          </div>

          <div className={styles.project_hover}>
            <div className={styles.project_hover_inner}>
              <div className={[styles.project_hover_inner, masks[mask]].join(' ')}>
                <p className={[styles.project_copy, theme.h3_tiempos].join(' ')}>
                  {props.projectLink.tagline}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={`${styles.project_meta} ${theme.mT11} ${theme.fdcont}`}>
          <h2 className={[styles.project_title, theme.h5_founders].join(' ')}>
            {props.projectLink.title}
          </h2>
          <ul className={styles.project_cat}>
            {props.projectLink.categories.map(function(cat, index) {
              return <li key={index}>{cat.title}</li>;
            })}
          </ul>
        </div>
      </Link>
    </li>
  );
}

export default Project;
