import { graphql } from 'gatsby';
import { chunk, differenceWith } from 'lodash';
import React from 'react';
import Container from '../components/container';
import GraphQLErrorList from '../components/graphql-error-list';
import SEO from '../components/seo';
import CategoryList from '../components/work-category-list';
import ProjectsGrid from '../components/work-projects-grid';
import Layout from '../containers/layout';
import { filterOutDocsWithoutSlugs, injectChunksAtInterval, mapEdgesToNodes } from '../lib/helpers';

export const query = graphql`
  query WorkPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...SanitySiteSettingsFragment
      socialImage {
        asset {
          url
        }
      }
    }
    categories: allSanityCategory(
      sort: { order: ASC, fields: order }
      filter: { featured: { eq: true } }
    ) {
      edges {
        node {
          title
          slug {
            current
          }
        }
      }
    }
    category: sanityCategory(slug: { current: { eq: "all" } }) {
      id
      title
      ProjectsList {
        _key
        _type
        maskingShape
        backgroundColor
        projectLink {
          ...SanityProjectLinkFragment
        }
      }
    }
    projects: allSanityProject(
      sort: { order: ASC, fields: order }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          ...SanityProjectFragment
        }
      }
    }
  }
`;

const WorkPage = props => {
  const { data, errors } = props;
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const categoryNodes = mapEdgesToNodes(data?.categories).filter(filterOutDocsWithoutSlugs);
  const projectNodes = mapEdgesToNodes(data?.projects).filter(filterOutDocsWithoutSlugs);

  const heroProjects = (data?.category?.ProjectsList ?? []).filter(p => !!p.projectLink);
  const heroProjectsNodes = heroProjects.map(project => ({
    ...project.projectLink,
    isHero: true,
    order: project.order,
    backgroundColor: project.backgroundColor,
    maskingShape: project.maskingShape,
  }));
  const heroProjectsChunks = chunk(heroProjectsNodes, 2);

  const filterProjectNodes = differenceWith(
    projectNodes,
    heroProjectsNodes,
    (a, b) => a.id === b.id
  ).sort((a, b) => a.order - b.order);

  const nodes = injectChunksAtInterval(filterProjectNodes, heroProjectsChunks, 9);

  return (
    <Layout>
      <SEO title='Work' ogImage={data?.site?.socialImage?.asset?.url} />
      <Container>
        <h1 hidden>Projects</h1>
        {categoryNodes && categoryNodes.length > 0 && <CategoryList nodes={categoryNodes} />}
        {projectNodes && projectNodes.length > 0 && <ProjectsGrid nodes={nodes} />}
      </Container>
    </Layout>
  );
};

export default WorkPage;
