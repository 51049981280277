import { graphql } from 'gatsby';
import { chunk, differenceWith } from 'lodash';
import React from 'react';
import Container from '../components/container';
import GraphQLErrorList from '../components/graphql-error-list';
import SEO from '../components/seo';
import CategoryList from '../components/work-category-list';
import ProjectsGrid from '../components/work-projects-grid';
import Layout from '../containers/layout';
import { filterOutDocsWithoutSlugs, injectChunksAtInterval, mapEdgesToNodes } from '../lib/helpers';

export const query = graphql`
  query CategoryTemplateQuery($id: String!) {
    categories: allSanityCategory(
      sort: { order: ASC, fields: order }
      filter: { featured: { eq: true } }
    ) {
      edges {
        node {
          title
          slug {
            current
          }
        }
      }
    }
    category: sanityCategory(id: { eq: $id }) {
      title
      ProjectsList {
        _key
        _type
        maskingShape
        backgroundColor
        projectLink {
          ...SanityProjectLinkFragment
        }
      }
    }
    projects: allSanityProject(
      sort: { order: ASC, fields: order }
      filter: {
        categories: { elemMatch: { id: { eq: $id } } }
        slug: { current: { ne: null } }
        publishedAt: { ne: null }
      }
    ) {
      edges {
        node {
          ...SanityProjectFragment
        }
      }
    }
  }
`;

const CategoryTemplateQuery = props => {
  const { data, errors } = props;
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const categoryNodes = mapEdgesToNodes(data?.categories).filter(filterOutDocsWithoutSlugs);
  const projectNodes = mapEdgesToNodes(data?.projects).filter(filterOutDocsWithoutSlugs);

  const heroProjects = (data?.category?.ProjectsList ?? []).filter(p => !!p.projectLink);
  const heroProjectsNodes = heroProjects.map(project => ({
    ...project.projectLink,
    isHero: true,
    order: project.order,
    backgroundColor: project.backgroundColor,
    maskingShape: project.maskingShape,
  }));
  const heroProjectsChunks = chunk(heroProjectsNodes, 2);

  const filterProjectNodes = differenceWith(
    projectNodes,
    heroProjectsNodes,
    (a, b) => a.id === b.id
  ).sort((a, b) => a.order - b.order);

  const nodes = injectChunksAtInterval(filterProjectNodes, heroProjectsChunks, 9);

  return (
    <Layout>
      <SEO title={data.category.title} description={data.category.description} />
      <Container>
        <h1 hidden>{data.category.title}</h1>
        {categoryNodes && categoryNodes.length > 0 && (
          <CategoryList activeCat={data.category} nodes={categoryNodes} />
        )}
        {projectNodes && projectNodes.length > 0 && <ProjectsGrid nodes={nodes} />}
      </Container>
    </Layout>
  );
};

export default CategoryTemplateQuery;
